import './styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Nftop from './views/NFTotal.js';
import NftopNavbar from './views/NFTotalNavbar.js';

function App() {
  return (
    <body>
      <NftopNavbar />
      <Nftop />
    </body>
  );
}

export default App;
