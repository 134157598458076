import React, { useState, useEffect, useCallback, useRef } from "react";

import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Spinner,
  Figure,
} from "react-bootstrap";
import { toPng } from "html-to-image";
import InfiniteScroll from "react-infinite-scroll-component";
import { PieChart } from "react-minimal-pie-chart";
import Discord from "./Discord";
import axios from "axios";

// import { ReactPhotoCollage } from "react-photo-collage";
// import NftopCollage from './NftopCollage.js';

const CONTROLLER = require("../controllers/nftAssetsController.js");

const COLORS = [
  "#f94144",
  "#f3722c",
  "#f9844a",
  "#f8961e",
  "#f9c74f",
  "#90be6d",
  "#43aa8b",
  "#4d908e",
  "#577590",
  "#277da1",
];

const Nftop = () => {
  const [ethAddr, setEthAddr] = useState("");
  const [nftList, setNftList] = useState([]);
  const [nftCollectionList, setNftCollectionList] = useState([]);
  const [total, setTotal] = useState();
  const [hasMoreNFTs, setHasMoreNFTs] = useState();
  const [trending, setTrending] = useState([]);
  const [selectedNFTs, setSelectedNFTs] = useState({});
  const [nftOrder, setNftOrder] = useState([]);
  const [numSelected, setNumSelected] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // set placeholder trending NFTs for now
    setTrendingPlaceholder();
  }, []);

  const ref = useRef(null);

  const onDownload = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "my-nftop.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);

  const setTrendingPlaceholder = () => {
    const temp = [
      { img: "/Group 17.png", name: "Beyonce's curation" },
      { img: "/Group 18.png", name: "Tame Impala’s curation" },
      { img: "/Group 19.png", name: "Punk 6529 curation" },
      { img: "/Group 20.png", name: "Roast BEETH curation" },
    ];
    setTrending(temp);
  };

  const handleFetchMore = () => {
    console.log("here");

    const os_options = {
      method: "GET",
      url: "https://api.opensea.io/api/v1/collections",
      params: {
        asset_owner: ethAddr,
        order_direction: "desc",
        offset: 0,
        limit: "300",
      },
    };

    axios
      .request(os_options)
      .then(function (response) {
        const assets = response.data.assets;
        const nftNextImageList = [];

        for (let index in assets) {
          let asset = assets[index];

          nftNextImageList.push({
            image: asset.image_original_url,
            imageUrl: asset.image_url,
            collectionName: asset.collection.name,
            collectionId: asset.asset_contract.address,
            tokenId: asset.token_id,
          });
        }
        setNftList(nftList.concat(nftNextImageList));
        setHasMoreNFTs(nftNextImageList.length === 9);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  async function setupNFTs() {
    setLoading(true);
    if (ethAddr.trim().length == 0) {
      alert("Enter your eth address to view and select your NFTs.");
      return;
    }

    var nextNftList = [];
    let hasMore = false;
    let page = 0;
    do {
      const os_options = {
        method: "GET",
        url: "https://api.opensea.io/api/v1/assets",
        params: {
          owner: ethAddr,
          order_direction: "desc",
          offset: (page * 50).toString(),
          limit: "50",
        },
      };

      await axios
        .request(os_options)
        .then(function (response) {
          const assets = response.data.assets;
          nextNftList = nextNftList.concat(assets);
          hasMore = assets.length === 50;
        })
        .catch(function (error) {
          console.error(error);
        });
      page += 1;
    } while (hasMore);
    setNftList(nextNftList);

    let collections = [];
    page = 0;
    do {
      const getCollections = {
        method: "GET",
        url: "https://api.opensea.io/api/v1/collections",
        params: {
          asset_owner: ethAddr,
          offset: (page * 300).toString(),
          limit: "300",
        },
      };

      await axios
        .request(getCollections)
        .then(function (response) {
          setNftCollectionList(response.data);
          hasMore = response.data.length === 300;
          collections = collections.concat(response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
      page += 1;
    } while (hasMore);
    setTotal(getTotal(nextNftList, collections));
  }

  const selectNft = (asset) => {
    var copy = Object.assign({}, selectedNFTs);
    var orderCopy = nftOrder.slice();
    if (copy[asset.tokenId] === undefined) {
      if (numSelected >= 4) {
        alert("You can only select 4 NFTs.");
        return;
      }
      copy[asset.tokenId] = asset;
      setNumSelected(numSelected + 1);
      orderCopy.push(asset.tokenId);
    } else {
      delete copy[asset.tokenId];
      setNumSelected(numSelected - 1);
      for (var i = 0; i < orderCopy.length; i++) {
        if (orderCopy[i] === asset.tokenId) {
          orderCopy.splice(i, 1);
          break;
        }
      }
    }
    setSelectedNFTs(copy);
    setNftOrder(orderCopy);
  };

  // const getAssets = () => {
  //   if(ethAddr.trim().length == 0) {
  //     alert("Enter your eth address to view and select your NFTs.");
  //     return;
  //   }
  //   const callback = (res) => {setAssets(res.data.assets)};
  //   const callbackOnError = (error) => {alert(error.message);};
  //   CONTROLLER.getAssets(ethAddr, callback, callbackOnError);
  // }

  function getTotal(nfts, collections) {
    const nftsWithValue = nfts.map((nft) => {
      const collectionId = nft.asset_contract.address;
      const collection = collections.find((collection) => {
        const primaryAssetContracts = collection.primary_asset_contracts;
        return primaryAssetContracts.find(
          (contract) => contract.address === collectionId
        );
      });

      const topBid = nft.top_bid;
      const lastSale = nft.last_sale;
      let totalSalePrice = 0;
      if (lastSale?.payment_token) {
        totalSalePrice =
          (parseFloat(lastSale.total_price) /
            Math.pow(10, lastSale.payment_token.decimals)) *
          parseFloat(lastSale.payment_token.eth_price);
      }
      const floorPrice = collection?.stats?.floor_price;
      const thisNftPrice = Math.max(
        topBid ?? 0,
        totalSalePrice ?? 0,
        floorPrice ?? 0
      );
      return { ...nft, price: thisNftPrice };
    });
    nftsWithValue.sort((a, b) => b.price - a.price);
    setNftList(nftsWithValue);
    const total = nftsWithValue.reduce((sum, nft) => {
      return sum + nft.price;
    }, 0);

    setLoading(false);
    return total.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 3,
    });
  }

  return (
    <Container style={{ marginTop: "25px" }}>
      <Row>
        <Col style={{ textAlign: "center" }}>
          <h1
            style={{ color: "#06d6a0", fontWeight: "bold", fontSize: "400%" }}
          >
            {" "}
            Calculate your NFT Portfolio Value
          </h1>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={2}></Col>

        {/* Eth address + NFT select */}
        <Col md={8} style={{ marginBottom: "20px" }}>
          <Row>
            <Col>
              <InputGroup>
                <Form.Control
                  placeholder="0x..."
                  onChange={(e) => {
                    setEthAddr(e.target.value);
                  }}
                />
                <Button
                  variant="success"
                  onClick={setupNFTs}
                  style={{
                    border: "1px solid #808B96",
                    padding: ".375rem .75rem",
                    backgroundColor: "#06d6a0",
                  }}
                >
                  Search
                </Button>
              </InputGroup>
              <div style={{ height: "10px" }}></div>
              <Form.Text muted>
                Enter your eth address to see the total value of your
                collection. We only support OpenSea NFTs for now.
              </Form.Text>
            </Col>
          </Row>
          <br />
          {loading ? (
            <Row style={{ marginTop: "50px" }}>
              <Col style={{ textAlign: "center" }}>
                <Spinner animation="border" variant="info" />
              </Col>
            </Row>
          ) : nftList.length == 0 ? (
            <Row style={{ marginTop: "50px" }}>
              <Col style={{ textAlign: "center" }}>
                <h1 style={{ fontSize: "1000%" }}> 🥧 </h1>
                <p> Your NFTotal will appear here </p>
              </Col>
            </Row>
          ) : (
            <>
              <Row style={{ marginTop: "50px" }}>
                <Col style={{ textAlign: "center" }}>
                  <h1 style={{ fontSize: "400%" }}>
                    NFTotal: <b>{total ?? "?"}</b>{" "}
                    <span style={{ fontFamily: "Sans-serif" }}>Ξ</span>
                  </h1>
                </Col>
              </Row>
              <Row style={{ marginTop: "50px" }}>
                <PieChart
                  data={nftList
                    .filter((nft) => nft.price > 0)
                    .slice(0, 10)
                    .map((nft, i) => {
                      const tokenId =
                        nft.token_id.length > 4
                          ? nft.token_id.substring(0, 4) + "..."
                          : nft.token_id;
                      return {
                        title: nft.collection.name + " #" + tokenId,
                        color: COLORS[i],
                        value: nft.price,
                      };
                    })}
                  label={(label) => {
                    return label.dataEntry.value + "Ξ " + label.dataEntry.title;
                  }}
                  labelPosition={75}
                  lineWidth={75}
                  labelStyle={{ fontSize: "1.5px", fontFamily: "sans-serif" }}
                />
              </Row>
            </>
          )}
        </Col>

        <Col xs={2}></Col>
      </Row>
      <br />
      <br />
      <Discord />
    </Container>
  );
};

export default Nftop;
